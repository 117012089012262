import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import images from 'assets/images';

// ----------------------------------------------------------------------

const Management = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	// 경력 목록 UI
	const renderCareer = (key) => {
		const managementObj = t(`경영진 소개.${key}.커리어`, {
			returnObjects: true
		});
		return (
			<Grid container item xs={12} mt={'0.8rem'}>
				{managementObj.map((item, index) => (
					<Grid
						item
						xs={12}
						display={'flex'}
						key={`Management-${index}`}
						flexWrap={'nowrap'}
					>
						<Typography variant={'caption6'} color={'gray6'} mr={'0.5rem'}>
							•
						</Typography>
						<Typography variant={'caption6'} color={'gray6'}>
							<Trans i18nKey={item} />
						</Typography>
					</Grid>
				))}
			</Grid>
		);
	};

	// 기타 경영진 UI
	const renderAnotherManagement = (key) => {
		return (
			<Grid
				container
				item
				xs={8}
				md={12}
				flexDirection={'column'}
				pl={matchUpMd ? 0 : '1.6rem'}
			>
				<Box>
					<Typography
						variant={matchUpMd ? 'h13' : 'caption2'}
						color={'black1'}
						fontWeight={700}
					>
						{t(`경영진 소개.${key}.이름`)}
					</Typography>
				</Box>
				<Box mt={'1.2rem'}>
					<Typography
						variant={matchUpMd ? 'caption3' : 'caption5'}
						color={'black1'}
						display={'block'}
					>
						{t(`경영진 소개.${key}.직책`)} <br />
						{i18n.exists(`경영진 소개.${key}.부서`) &&
							t(`경영진 소개.${key}.부서`)}
					</Typography>
				</Box>

				<Box>{renderCareer(key)}</Box>
			</Grid>
		);
	};

	// ----------------------------------------------------------------------

	return (
		<Container
			id={'company-management'}
			maxWidth={'lg'}
			className={'px-0'}
			sx={{
				pb: matchUpMd ? 0 : '6rem'
			}}
		>
			{/* 경영진 소개 상단 문구 */}
			<Grid
				container
				pt={matchUpMd ? '12rem' : '8rem'}
				pb={matchUpMd ? '2rem' : '2rem'}
			>
				<Typography
					variant={matchUpMd ? 'h9' : 'caption2'}
					fontWeight={700}
					color={'black2'}
					px={matchUpMd ? 0 : '2rem'}
					letterSpacing={i18n.language === 'jp' && '-0.07rem'}
				>
					<Trans
						i18nKey={
							matchUpMd
								? '경영진 소개.사업의 주체인 Founder와 Co-Founder는 국내 최고의 경력을 보유한 전문가 집단으로 구성되어 있습니다'
								: '경영진 소개.M사업의 주체인 Founder와 Co-Founder는 국내 최고의 경력을 보유한 전문가 집단으로 구성되어 있습니다'
						}
					/>
				</Typography>
			</Grid>

			<Container
				sx={{
					px: matchUpMd ? 0 : '2rem',
					pt: matchUpMd ? '8rem' : '2.8rem'
				}}
			>
				<Grid container>
					<Grid item container xs={12} md={6.5} mb={matchUpMd ? 0 : '2.8rem'}>
						<Grid item xs={4} md={5.5}>
							<Box
								className={'CompanyManagement-Img-Wrapper'}
								pt={
									matchUpMd
										? 'calc(340 / 282 * 100%)'
										: 'calc(148 / 116 * 100%)'
								}
							>
								<img
									src={images.CompanyManagement1}
									alt={'CompanyManagement1'}
								/>
							</Box>
						</Grid>

						<Grid item xs>
							<Box
								sx={{
									...(matchUpMd && {
										pt: '4rem',
										pl: 3,
										pr: '5.6rem'
									}),
									...(!matchUpMd && {
										pt: 0,
										pl: '1.6rem',
										pr: 0,
										height: '100%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-end'
									})
								}}
							>
								<Box>
									<Typography variant={'h13'} color={'black1'}>
										{t('경영진 소개.조한종.이름')}
									</Typography>
								</Box>

								<Box mt={matchUpMd ? '1.6rem' : '0.4rem'}>
									<Typography
										variant={matchUpMd ? 'caption2' : 'caption5'}
										color={'black1'}
									>
										{t('경영진 소개.조한종.現 (주)콘텐츠마당 대표이사')}
									</Typography>
								</Box>

								{matchUpMd && renderCareer('조한종')}
							</Box>
						</Grid>
						{!matchUpMd && renderCareer('조한종')}
					</Grid>

					<Grid item container xs={12} md={2.75} pl={matchUpMd ? '1.4rem' : 0}>
						<Grid item container xs={12} pt={matchUpMd ? 0 : 4}>
							<Grid item xs={3.5} md={12}>
								<Box
									className={'CompanyManagement-Img-Wrapper'}
									pt={
										matchUpMd
											? 'calc(200 / 260 * 100%)'
											: 'calc(130 / 103 * 100%)'
									}
								>
									<img
										src={images.CompanyManagement4}
										alt={'CompanyManagement4'}
									/>
								</Box>
							</Grid>

							<Grid item xs md={12} height={'100%'}>
								<Box pt={matchUpMd ? '2rem' : 0}>
									{renderAnotherManagement('윤용수')}
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
};

export default Management;
