import {
	Box,
	ButtonBase,
	Dialog,
	Divider,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'assets/svg';
import { newsInfo } from 'data/news-info';

// ----------------------------------------------------------------------

const PaperComponent = ({ props, matchUpMd }) => {
	return (
		<Paper
			{...props}
			sx={{
				borderRadius: '2.4rem',
				zIndex: 50,
				...(!matchUpMd && { mt: '9rem !important' })
			}}
		/>
	);
};

// ----------------------------------------------------------------------

const NoticeDialog = ({ open, setOpen }) => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const noticeData = newsInfo[0];

	const handleClose = () => {
		setOpen(false);
	};

	// ----------------------------------------------------------------------

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			scroll={'body'}
			maxWidth={'md'}
			sx={{ zIndex: 100 }}
			PaperComponent={(props) => (
				<PaperComponent props={props} matchUpMd={matchUpMd} />
			)}
		>
			<Box sx={{ p: matchUpMd ? '4rem' : '2.4rem' }}>
				{/* 헤더 */}
				<Stack
					flexDirection={'row'}
					alignItems={'start'}
					justifyContent={'space-between'}
				>
					<Stack>
						<Typography
							variant={matchUpMd ? 'caption2' : 'caption3'}
							fontWeight={600}
							color={'gray6'}
						>
							{t('메뉴.새소식')}
						</Typography>
						<Typography
							variant={matchUpMd ? 'h13' : 'caption2'}
							fontWeight={700}
						>
							{i18n.language === 'ko'
								? noticeData.title.ko
								: noticeData.title[i18n.language]}
						</Typography>
					</Stack>
					<ButtonBase disableRipple disableTouchRipple onClick={handleClose}>
						<IconClose width={28} height={28} stroke={theme.palette.black1} />
					</ButtonBase>
				</Stack>

				{/* 구분선 */}
				<Divider sx={{ my: matchUpMd ? '2rem' : '1.6rem' }} />

				{/* 내용 */}
				<Typography
					variant={matchUpMd ? 'caption3' : 'caption5'}
					color={'black2'}
					whiteSpace={'break-spaces'}
				>
					{i18n.language === 'ko'
						? noticeData.contents.ko
						: noticeData.contents[i18n.language]}
				</Typography>
			</Box>
		</Dialog>
	);
};

export default NoticeDialog;
