import { useTranslation } from 'react-i18next';

import {
	Box,
	ButtonBase,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { IconArrowLeft, IconArrowRight } from 'assets/svg';

const MinimalPagination = ({ page, setPage, totalPage }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const handlePrevPage = () => {
		if (page === 1) {
			return;
		}
		setPage(page - 1);
	};

	const handleNextPage = () => {
		if (page === totalPage) {
			return;
		}
		setPage(page + 1);
	};

	return (
		<Box className={matchUpMd ? 'MinimalPagination' : 'M-MinimalPagination'}>
			<ButtonBase
				className={'MinimalPagination-Next-Button'}
				disabled={page === 1}
				onClick={handlePrevPage}
			>
				<IconArrowLeft width={12} height={12} stroke={theme.palette.black1} />
				<Typography variant={matchUpMd ? 'caption3' : 'caption5'} ml={'8px'}>
					{t('이전')}
				</Typography>
			</ButtonBase>
			<Box
				className={
					matchUpMd ? 'MinimalPagination-Page' : 'M-MinimalPagination-Page'
				}
			>
				<Typography
					variant={'caption3'}
					color={theme.palette.black2}
					fontWeight={600}
				>
					{page}/{totalPage}
				</Typography>
			</Box>
			<ButtonBase
				className={'MinimalPagination-Next-Button'}
				disabled={page === totalPage}
				onClick={handleNextPage}
			>
				<Typography variant={matchUpMd ? 'caption3' : 'caption5'} mr={'8px'}>
					{t('다음')}
				</Typography>
				<IconArrowRight width={12} height={12} stroke={theme.palette.black1} />
			</ButtonBase>
		</Box>
	);
};

export default MinimalPagination;
