export const newsOfficial = [
	{
		date: {
			ko: '2024-04',
			en: 'Apr, 2024',
			jp: '2024年 4月'
		},
		title: {
			ko: "'스키즈·아이유·임영웅·제베원·김호중…' 올해의 트렌드는? KGMA, 월간 시상 팬투표로 포문",
			en: "'SKZ・IU・Young-woong Lim・ZB1・Hojoong Kim...' Who's the trend of the year? KGMA launches monthly awards with fan voting",
			jp: '「スキズ、IU、イムヨンウン、ZB1…」今年のトレンドは？KGMA、月間表彰ファン投票でスタート'
		},
		url: 'https://n.news.naver.com/entertain/article/241/0003342174'
	},
	{
		date: {
			ko: '2024-02',
			en: 'Feb, 2024',
			jp: '2024年 1月'
		},
		title: {
			ko: "일간스포츠 '코리아 그랜드 뮤직 어워즈', 골든디스크 넘어 최고의 시상식으로…역대급 아티스트 총출동 예상",
			en: "The Daily Sports 'Korea Grand Music Awards', the Best Award Over Golden Disc... Expect Super Popular Artists to Perform",
			jp: 'The Daily Sports「コリアグランドミュージックアワード、ゴールデンディスクを超える最高のアワード...今までにないアーティストの出演に期待'
		},
		url: 'https://isplus.com/article/view/isp202402160002'
	},
	{
		date: {
			ko: '2024-01',
			en: 'Jan, 2024',
			jp: '2024年 1月'
		},
		title: {
			ko: "'유니버스 티켓' 글로벌 팬 뜨거운 열광 속 '유니스 데뷔' 기대",
			en: "'Universe Ticket' Global fans are eagerly awaiting 'UNIS' debut",
			jp: '「ユニバースチケット」グローバルファン熱い注目の中「UNISデビュー」期待'
		},
		url: 'https://www.dailysportshankook.co.kr/news/articleView.html?idxno=319491'
	},
	{
		date: {
			ko: '2023-07',
			en: 'Jul, 2023',
			jp: '2023年 7月'
		},
		title: {
			ko: '"매일 8명의 소녀에게 투표하세요"…\'유니버스 티켓\', 사전 투표 오픈',
			en: '"Vote for eight girls every day" ... \'Universe Ticket\', pre-voting is now open',
			jp: '「毎日8人の少女に投票してください」…『ユニバースチケット』、事前投票オープン'
		},
		url: 'https://ent.sbs.co.kr/news/article.do?article_id=E10010274074&plink=ORI&cooper=NAVER'
	},
	{
		date: {
			ko: '2023-03',
			en: 'Mar, 2023',
			jp: '2023年 3月'
		},
		title: {
			ko: '33년 전통 서울가요대상, 월간 시상식 투표에 최대 규모 팬덤 플랫폼 참여',
			en: 'Largest fandom platforms collaborate on voting for the 33-year-old Seoul Music Awards monthly awards show',
			jp: '33歳のソウルガヨ賞、月例賞の投票に最大のファンダム・プラットフォームが参加'
		},
		url: 'https://n.news.naver.com/entertain/article/468/0000932675'
	},
	{
		date: {
			ko: '2023-03',
			en: 'Mar, 2023',
			jp: '2023年 3月'
		},
		title: {
			ko: '랩큐-콘텐츠마당, 인공지능 기술 활용한 글로벌 K-pop 플랫폼 구축 위한 전략적 제휴',
			en: 'LabQ-Contents Madang, a Strategic Alliance to Build a Global K-pop Platform using Artificial Intelligence Technology',
			jp: 'LabQ-コンテンツマダン、AI (人工知能技術)を活用したグローバルK-POPプラットフォーム構築のための戦略的提携'
		},
		url: 'https://www.aitimes.kr/news/articleView.html?idxno=27506'
	},
	{
		date: {
			ko: '2023-01',
			en: 'Jan, 2023',
			jp: '2023年 1月'
		},
		title: {
			ko: '팬캐스트, ‘32회 서울가요대상’ 부스이벤트 성료',
			en: 'Fancast, the 32nd “Seoul Music Awards” finished booth event successfully',
			jp: 'Fancast、「第32回ソウルミュージックアワード」ブースイベント盛況に終了'
		},
		url: 'http://www.sentv.co.kr/news/view/644449'
	},
	{
		date: {
			ko: '2022-12',
			en: 'Dec, 2022',
			jp: '2022年 12月'
		},
		title: {
			ko: '서울가요대상 공식 투표 앱 ‘FANCAST’ 글로벌 팬 위한 커머스 서비스 론칭',
			en: "Seoul Music Awards' official voting app 'FANCAST' launches an e-commerce service for global fans.",
			jp: 'ソウル歌謡大賞公式投票アプリ「FANCAST」グローバルファンのためのコマースサービスを開始'
		},
		url: 'https://www.sedaily.com/NewsView/26F0JJO227'
	},
	{
		date: {
			ko: '2022-12',
			en: 'Dec, 2022',
			jp: '2022年 12月'
		},
		title: {
			ko: '콘텐츠마당, ‘팬캐스트(FANCAST)‘ 1.0 버전 정식 오픈',
			en: "Contents Madang officially opens 1.0 version of 'FANCAST'",
			jp: 'コンテンツマダン、「ファンキャスト（FANCAST）」1.0バージョン正式オープン'
		},
		url: 'https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A202212060079&t=NN'
	},
	{
		date: {
			ko: '2022-11',
			en: 'Nov, 2022',
			jp: '2022年 11月'
		},
		title: {
			ko: '핑거, 콘텐츠 플랫폼 기업 ‘콘텐츠마당’에 지분 투자',
			en: "Finger Invests Equity in Content Platform Company 'Contents Madang'",
			jp: 'フィンガー、コンテンツプラットフォーム企業「コンテンツマダン」に持分投資'
		},
		url: 'https://www.pinpointnews.co.kr/news/articleView.html?idxno=152749'
	},
	{
		date: {
			ko: '2022-07',
			en: 'Jul, 2022',
			jp: '2022年 7月'
		},
		title: {
			ko: '콘텐츠마당, 연제협과 맞손…”케이팝 슈퍼루키 육성”',
			en: 'Contents Madang, cooperation with KEPA..."Cultivating K-pop Super Rookie".',
			jp: 'コンテンツマダン、K-POPスーパールーキーの育成に向け演製協と協力...「K-POPスーパールーキーの育成」'
		},
		url: 'https://www.edaily.co.kr/news/read?newsId=03732646632393864&mediaCodeNo=257&OutLnkChk=Y'
	},
	{
		date: {
			ko: '2022-07',
			en: 'Jul, 2022',
			jp: '2022年 7月'
		},
		title: {
			ko: '㈜콘텐츠마당, 마호캐스트와 K-POP 대중문화산업 콘텐츠 육성위한 업무협약 체결',
			en: 'Contents Madang Inc. and Mahocast signed a business agreement to foster content in the K-POP pop culture industry.',
			jp: 'コンテンツマダン、マホキャストとK-POP大衆文化産業コンテンツ育成のための業務協約締結'
		},
		url: 'http://www.segyebiz.com/newsView/20220721508555?OutUrl=naver'
	},
	{
		date: {
			ko: '2022-07',
			en: 'Jul, 2022',
			jp: '2022年 7月'
		},
		title: {
			ko: '마호캐스트, ㈜콘텐츠마당과 K-POP 대중문화산업 콘텐츠 육성',
			en: 'Mahocast Incorporates Content Madang and K-POP Popular Culture Industry Content Development',
			jp: 'マホキャスト、㈱コンテンツマダンとK-POP大衆文化産業コンテンツ育成'
		},
		url: 'https://view.asiae.co.kr/article/2022072110011031161'
	}
];
