import ContentsMadang from './contentsmadang_logo.png';

import FancastLogoVertical from './fancast_logo_vertical.png';
import FancastLogoHorizontal from './fancast_logo_horizontal.png';

import CompanyMain from './company/main.png';

import CompanyHistory1 from './company/history/1.png';
import CompanyHistory2 from './company/history/2.png';
import CompanyHistory3 from './company/history/3.png';
import CompanyHistory4 from './company/history/4.png';
import CompanyHistory5 from './company/history/5.png';
import CompanyHistory6 from './company/history/6.png';
import CompanyHistory7 from './company/history/7.png';
import CompanyHistory8 from './company/history/8.png';

import CompanyIntro1 from './company/intro/1.png';
import CompanyMoveBusiness from './company/move_business.png';

import CompanyManagement1 from './company/management/1.png';
import CompanyManagement2 from './company/management/2.png';
import CompanyManagement3 from './company/management/3.png';
import CompanyManagement4 from './company/management/4.png';

import IconPlusBlack from './icon_plus_black.png';
import IconArrowRightWhite from './icon_arrow_right_white.png';

import IconArrowRightGroupBlue from './ic_arrow_right_group_blue.png';
import IconArrowLeftGroupBlue from './ic_arrow_left_group_blue.png.png';
import IconArrowBothBlue from './ic_arrow_both_blue.png';

import IconArrowRightGroupGray from './icon_arrow_right_group_gray.png';

import BusinessMessage from './business/message.png';
import BusinessCase from './business/case.png';

import BusinessIntro1 from './business/intro/1.png';
import BusinessIntro2 from './business/intro/2.png';
import BusinessIntro3 from './business/intro/3.png';
import BusinessIntro4 from './business/intro/4.png';
import BusinessIntro5 from './business/intro/5.png';

import IconSportSeoul from './business/partner/ic_sportseoul.png';
import IconIlganSports from './business/partner/ic_ilgansports.png';
import IconHanteoChart from './business/partner/ic_hanteochart.png';
import IconCircleChart from './business/partner/ic_circlechart.png';
import IconEna from './business/partner/ic_ena.png';

import IconHybe from './business/partner/ic_hybe.png';
import IconSm from './business/partner/ic_sm.png';
import IconJyp from './business/partner/ic_jyp.png';
import IconStarShip from './business/partner/ic_starship.png';
import IconRbw from './business/partner/ic_rbw.png';
import IconCube from './business/partner/ic_cube.png';
import IconJellyfish from './business/partner/ic_jellyfish.png';
import IconKq from './business/partner/ic_kq.png';
import IconFantagio from './business/partner/ic_fantagio.png';
import IconFandF from './business/partner/ic_f&f.png';
import IconFirstOne from './business/partner/ic_firstone.png';
import IconNch from './business/partner/ic_nch.png';
import IconMakeStar from './business/partner/ic_makestar.png';
import IconWoollim from './business/partner/ic_woollim.png';
import Icon143 from './business/partner/ic_143.png';

import IconKepa from './business/partner/ic_kepa.png';
import IconLiveConnect from './business/partner/ic_liveconnect.png';
import IconIdolChamp from './business/partner/ic_idolchamp.png';
import IconMubeat from './business/partner/ic_mubeat.png';
import IconPodoal from './business/partner/ic_podoal.png';
import IconWhosFan from './business/partner/ic_whosfan.png';
import IconBlip from './business/partner/ic_blip.png';

import IconFinger from './business/partner/ic_finger.png';

import VideoContents from './business/connection/video_contents.png';
import GlobalConcert from './business/connection/global_concert.png';
import XRConcert from './business/connection/xr_concert.png';
import VideoDance from './business/connection/video_dance.png';
import GlobalPersonalContents from './business/connection/global_personal_contents.png';
import AwardsMarketing from './business/connection/awards_marketing.png';
import Vote from './business/connection/vote.png';
import Transmission from './business/connection/transmission.png';
import Collaboration from './business/connection/collaboration.png';
import AwardsBrandMarketing from './business/connection/awards_brand_marketing.png';

import IconArrowRightGray from './ic_arrow_right_gray.png';

import CompanySub from './company/sub.png';

import BusinessMain from './business/main.png';
import BusinessContact from './business/contact.png';

import ServiceMain from './service/main.png';
import ServiceSub from './service/sub.png';
import ServiceFancast from './service/fancast.png';

import NewsMain from './news/main.png';

import News1 from './news/news1.jpg';

export default {
	// 콘텐츠마당 로고
	ContentsMadang,
	// 팬캐스트 로고
	FancastLogoVertical,
	FancastLogoHorizontal,
	// 회사 소개
	CompanyMain,
	CompanySub,
	// 회사 소개 > 회사 개요
	CompanyIntro1,
	// 회사 소개 > 히스토리
	CompanyHistory1,
	CompanyHistory2,
	CompanyHistory3,
	CompanyHistory4,
	CompanyHistory5,
	CompanyHistory6,
	CompanyHistory7,
	CompanyHistory8,
	// 회사 소개 > 사업 소개 이동
	CompanyMoveBusiness,
	// 회사 소개 > 경영진 소개
	CompanyManagement1,
	CompanyManagement2,
	CompanyManagement3,
	CompanyManagement4,
	// 사업 소개
	BusinessMain,
	// 사업 소개 > 사업 개요
	BusinessIntro1,
	BusinessIntro2,
	BusinessIntro3,
	BusinessIntro4,
	BusinessIntro5,
	// 사업 소개 > 제휴 문의
	BusinessContact,
	// 서비스 소개
	ServiceMain,
	ServiceSub,
	ServiceFancast,
	// 뉴스룸
	NewsMain,
	News1,
	//
	IconPlusBlack,
	IconArrowRightWhite,
	IconArrowRightGroupBlue,
	IconArrowLeftGroupBlue,
	IconArrowBothBlue,
	BusinessMessage,
	BusinessCase,
	VideoContents,
	GlobalConcert,
	XRConcert,
	VideoDance,
	GlobalPersonalContents,
	AwardsMarketing,
	Vote,
	Transmission,
	Collaboration,
	AwardsBrandMarketing,
	IconArrowRightGray,
	// business > partner
	IconSportSeoul,
	IconIlganSports,
	IconHanteoChart,
	IconCircleChart,
	IconEna,

	IconHybe,
	IconSm,
	IconJyp,
	IconStarShip,
	IconRbw,
	IconCube,
	IconJellyfish,
	IconKq,
	IconFantagio,
	IconFandF,
	IconFirstOne,
	IconNch,
	IconMakeStar,
	IconWoollim,
	Icon143,

	IconKepa,
	IconLiveConnect,
	IconIdolChamp,
	IconMubeat,
	IconPodoal,
	IconWhosFan,
	IconBlip,

	IconFinger,
	IconArrowRightGroupGray
};
