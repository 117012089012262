import { SubIndex, MoreView } from 'views/components';

import { MainImage, Info, Official } from 'views/news/components';

// ----------------------------------------------------------------------

const newsSubIndex = [
	{
		text: '메뉴.새소식',
		value: 'news-info'
	},
	{
		text: '메뉴.보도자료',
		value: 'news-official'
	}
];

// ----------------------------------------------------------------------

const NewsRootView = () => {
	return (
		<div>
			<MainImage />
			<SubIndex data={newsSubIndex} />
			<Info />
			<Official />
			<MoreView />
		</div>
	);
};

export default NewsRootView;
