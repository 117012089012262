import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

import images from 'assets/images';

import { IconArrowSimpleTop, IconArrowSimpleDown } from 'assets/svg';

// ----------------------------------------------------------------------

const VIDEO_CONTENTS = 1;
const GLOBAL_AWARDS = 2;
const BRAND_MARKETING = 3;

const videoContentsImg = [
	images.VideoContents,
	images.GlobalConcert,
	images.XRConcert,
	images.VideoDance,
	images.GlobalPersonalContents,
	images.AwardsMarketing
];

const globalAwardsImg = [images.Vote, images.Transmission];

const brandMarketingImg = [images.Collaboration, images.AwardsBrandMarketing];

// ----------------------------------------------------------------------

const ConnectionBusiness = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const videoContentsDescription = t('연계 사업.영상 콘텐츠 사업', {
		returnObjects: true
	});
	const globalAwardsDescription = t('연계 사업.글로벌 어워즈 사업', {
		returnObjects: true
	});
	const brandMarketingDescription = t('연계 사업.브랜드 마케팅 사업', {
		returnObjects: true
	});

	const [currentMoreViewList, setCurrentMoreViewList] = useState([
		VIDEO_CONTENTS
	]);

	const handleClickCardCategory = (categoryId) => {
		const findIndexResult = currentMoreViewList.findIndex(
			(item) => item === categoryId
		);

		let tmpCurrentMoreViewList = currentMoreViewList.slice();
		if (findIndexResult === -1) {
			tmpCurrentMoreViewList.push(categoryId);
		} else {
			tmpCurrentMoreViewList.splice(findIndexResult, 1);
		}
		setCurrentMoreViewList(tmpCurrentMoreViewList);
	};

	const renderCardCategory = useCallback(
		(title, categoryId) => {
			if (matchUpMd) {
				return (
					<Box>
						<Typography variant={'caption1'} color={'black1'} fontWeight={700}>
							{t(title)}
						</Typography>
					</Box>
				);
			}

			return (
				<Box
					display={'flex'}
					alignItems={'center'}
					justifyContent={'space-between'}
					onClick={() => handleClickCardCategory(categoryId)}
				>
					<Typography variant={'caption2'} color={'black1'} fontWeight={700}>
						{t(title)}
					</Typography>
					{currentMoreViewList.findIndex((item) => item === categoryId) !==
					-1 ? (
						<IconArrowSimpleTop
							width={16}
							height={8}
							stroke={theme.palette.black2}
						/>
					) : (
						<IconArrowSimpleDown
							width={16}
							height={8}
							stroke={theme.palette.black2}
						/>
					)}
				</Box>
			);
		},
		[matchUpMd, currentMoreViewList]
	);

	const renderCards = useCallback(
		(descriptionList, img, categoryId) => {
			const findIndex = currentMoreViewList.findIndex(
				(item) => item === categoryId
			);

			if (!matchUpMd && findIndex === -1) {
				return <></>;
			}

			return (
				<Grid
					item
					xs={12}
					md={9}
					container
					rowSpacing={matchUpMd ? 10 : 4}
					columnSpacing={3}
					mb={matchUpMd ? 0 : '5rem'}
				>
					{descriptionList.map((item, index) => (
						<Grid
							item
							xs={12}
							md={6}
							ml={matchUpMd ? 0 : '2rem'}
							key={`ConnectionBusiness-Description-${index}`}
						>
							<Box className={'Connection-Business-Img-Wrapper'}>
								<img src={img[index]} alt={item['제목']} />
							</Box>
							<Box mt={matchUpMd ? '2rem' : '1.5rem'}>
								<Typography
									variant={matchUpMd ? 'h13' : 'caption3'}
									color={'black1'}
									fontWeight={matchUpMd ? 700 : 600}
								>
									{item['제목']}
								</Typography>
							</Box>
							<Box mt={'1rem'}>
								<Typography
									variant={
										matchUpMd
											? 'caption3'
											: i18n.language === 'ko'
											? 'caption5'
											: 'caption6'
									}
									color={'gray6'}
								>
									{item['설명']}
								</Typography>
							</Box>
						</Grid>
					))}
				</Grid>
			);
		},
		[matchUpMd, currentMoreViewList]
	);

	// ----------------------------------------------------------------------

	return (
		<Box id={'business-connection'} pt={matchUpMd ? '12rem' : '8rem'}>
			<Container maxWidth={'lg'}>
				<Grid
					container
					px={matchUpMd ? 0 : '2rem'}
					mb={matchUpMd ? '4rem' : '1rem'}
				>
					<Grid item xs={12}>
						<Typography
							variant={matchUpMd ? 'h5' : 'h12'}
							color={'black1'}
							fontWeight={700}
						>
							{t('메뉴.연계 사업')}
						</Typography>
					</Grid>
				</Grid>
				<Grid container px={matchUpMd ? 0 : '2rem'}>
					<Grid item xs={12} md={3} py={matchUpMd ? 0 : '3rem'}>
						{renderCardCategory(
							'연계 사업.제목.영상 콘텐츠 사업',
							VIDEO_CONTENTS
						)}
					</Grid>

					{renderCards(
						videoContentsDescription,
						videoContentsImg,
						VIDEO_CONTENTS
					)}
				</Grid>
				{!matchUpMd && <Box height={'1px'} bgcolor={'gray3'} mx={'2rem'} />}
				<Grid container mt={matchUpMd ? '8rem' : 0} px={matchUpMd ? 0 : '2rem'}>
					<Grid item xs={12} md={3} py={matchUpMd ? 0 : '3rem'}>
						{renderCardCategory(
							'연계 사업.제목.글로벌 어워즈 사업',
							GLOBAL_AWARDS
						)}
					</Grid>

					{renderCards(globalAwardsDescription, globalAwardsImg, GLOBAL_AWARDS)}
				</Grid>
				{!matchUpMd && <Box height={'1px'} bgcolor={'gray3'} mx={'2rem'} />}
				<Grid
					container
					mt={matchUpMd ? '8rem' : 0}
					px={matchUpMd ? 0 : '2rem'}
					pb={matchUpMd ? '5rem' : 0}
				>
					<Grid item xs={12} md={3} py={matchUpMd ? 0 : '3rem'}>
						{renderCardCategory(
							'연계 사업.제목.브랜드 마케팅 사업',
							BRAND_MARKETING
						)}
					</Grid>
					{renderCards(
						brandMarketingDescription,
						brandMarketingImg,
						BRAND_MARKETING
					)}
				</Grid>
			</Container>
		</Box>
	);
};

export default ConnectionBusiness;
