const muiStyleOverride = () => {
	return {
		MuiContainer: {
			styleOverrides: {
				root: {
					padding: 0
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					underline: 'none',
					textDecoration: 'none'
				}
			}
		}
	};
};

export default muiStyleOverride;
