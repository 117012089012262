import { Trans, useTranslation } from 'react-i18next';

import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

// ----------------------------------------------------------------------

const Intro = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	// ----------------------------------------------------------------------

	return (
		<Container id={'company-intro'} maxWidth={'lg'} className={'px-0'}>
			<Grid container py={matchUpMd ? '12rem' : '6rem'}>
				<Grid
					item
					xs={12}
					md={5}
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
				>
					<Typography variant={matchUpMd ? 'h1' : 'h7'} color={'black2'}>
						{t('회사 개요.CONTENTS')}
					</Typography>
					<Typography
						variant={matchUpMd ? 'caption3' : 'caption5'}
						color={'gray6'}
						align={'center'}
					>
						<Trans i18nKey={'회사 개요.세상에 존재하는 모든 문화적 가치'} />
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={2}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<Box my={matchUpMd ? 0 : '4rem'}>
						<img
							src={images.IconPlusBlack}
							width={matchUpMd ? '70px' : '40px'}
							height={matchUpMd ? '70px' : '40px'}
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={5}
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
				>
					<Typography variant={matchUpMd ? 'h1' : 'h7'} color={'black2'}>
						{t('회사 개요.MADANG')}
					</Typography>
					<Typography
						variant={matchUpMd ? 'caption3' : 'caption5'}
						color={'gray6'}
						align={'center'}
					>
						<Trans
							i18nKey={'회사 개요.가치를 더욱 빛나게 하는 문화 소통의 장'}
						/>
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				pt={matchUpMd ? 0 : '4rem'}
				pb={matchUpMd ? '10rem' : '6rem'}
			>
				<Grid
					item
					xs={12}
					md={5}
					px={matchUpMd ? 0 : '2rem'}
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'center'}
				>
					<Box>
						<Typography
							variant={
								matchUpMd ? 'h2' : i18n.language === 'ko' ? 'h10' : 'h12'
							}
							color={'black2'}
							letterSpacing={'-0.1rem'}
						>
							<Trans i18nKey={'회사 개요.콘텐츠마당은,'} />
						</Typography>
					</Box>
					<Box mt={matchUpMd ? '2.4rem' : '1rem'}>
						<Typography
							variant={matchUpMd ? 'caption1' : 'caption3'}
							color={'black1'}
						>
							<Trans
								i18nKey={
									'회사 개요.문화의 다양성을 반영하는 글로벌 엔터 테크 플랫폼 기업입니다.'
								}
							/>
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={7} pt={matchUpMd ? 0 : '2rem'}>
					<Box
						className={'CompanyIntro-Img-Wrapper'}
						pt={matchUpMd ? 'calc(180 / 690 * 100%)' : 'calc(160 / 375 * 100%)'}
					>
						<img
							src={images.CompanyIntro1}
							style={{ borderRadius: matchUpMd ? '9.5rem' : 0 }}
						/>
					</Box>
				</Grid>
			</Grid>
			{matchUpMd && <Box height={'1px'} bgcolor={'gray3'} />}
		</Container>
	);
};

export default Intro;
