import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { IconArrowRight } from 'assets/svg';

import { newsOfficial } from 'data/news-official';

import { MinimalPagination } from 'views/components';

// ----------------------------------------------------------------------

const Official = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const limit = 10;
	const [page, setPage] = useState(1);
	const offset = (page - 1) * limit;

	const [currentList, setCurrentList] = useState([]);

	useEffect(() => {
		const tmpList = newsOfficial.slice(offset, offset + limit);
		setCurrentList(tmpList);
	}, [page]);

	const renderOfficial = () => {
		return currentList.map((item, index) => (
			<Grid item xs={12} container key={`NewsOfficial-${index}`}>
				<Box
					className={'NewsOfficial-Item'}
					onClick={() => window.open(item.url, '_blank')}
					py={matchUpMd ? '4rem' : '2.5rem'}
				>
					<Box mr={'3rem'}>
						<Box>
							<Typography
								variant={matchUpMd ? 'caption5' : 'caption7'}
								color={'gray5'}
							>
								{item.date[i18n.language] === undefined
									? item.date['ko']
									: item.date[i18n.language]}
							</Typography>
						</Box>
						<Box mt={matchUpMd ? '1.6rem' : '0.5rem'}>
							<Typography
								variant={matchUpMd ? 'h13' : 'caption2'}
								color={'black1'}
								fontWeight={700}
								letterSpacing={i18n.language === 'jp' && '-0.1rem'}
							>
								{item.date[i18n.language] === undefined
									? item.title['ko']
									: item.title[i18n.language]}
							</Typography>
						</Box>
					</Box>
					<Box>
						<IconArrowRight
							className={'NewsOfficial-Arrow'}
							width={matchUpMd ? 36 : 24}
							height={matchUpMd ? 24 : 16}
							stroke={matchUpMd ? theme.palette.gray3 : theme.palette.black1}
						/>
					</Box>
				</Box>
				{newsOfficial.length !== index + 1 && (
					<Box width={'100%'} height={'1px'} bgcolor={'gray3'} />
				)}
			</Grid>
		));
	};

	// ----------------------------------------------------------------------

	return (
		<Container id={'news-official'} maxWidth={'lg'} className={'px-0'}>
			<Grid
				container
				pt={'6rem'}
				px={matchUpMd ? 0 : '2rem'}
				pb={matchUpMd ? '8rem' : '6rem'}
			>
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
				>
					<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black2'}>
						{t('메뉴.보도자료')}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					container
					direction={'row'}
					justifyContent={matchUpMd ? 'center' : 'flex-start'}
				>
					<Typography
						mt={matchUpMd ? '1.2rem' : '0.6rem'}
						variant={matchUpMd ? 'caption1' : 'caption3'}
						color={'black1'}
					>
						{t('뉴스룸.언론 속 콘텐츠마당')}
					</Typography>
				</Grid>
				<Grid container mt={matchUpMd ? '8rem' : '3rem'}>
					{renderOfficial()}
				</Grid>
				<Grid
					container
					direction={'row'}
					justifyContent={'flex-end'}
					mt={matchUpMd ? '7rem' : '4rem'}
				>
					<MinimalPagination
						page={page}
						setPage={setPage}
						totalPage={Math.ceil(newsOfficial.length / 10)}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Official;
