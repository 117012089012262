// 팬캐스트 랜딩 페이지
export const URL_FANCAST_LANDING = 'https://fanca.io/';

// 팬캐스트 구글 플레이 스토어
export const URL_FANCAST_GOOGLE_PLAY_STORE =
	'https://play.google.com/store/apps/details?id=com.contentsmadang.fancast';

// 팬캐스트 애플 앱 스토어
export const URL_FANCAST_APP_STORE =
	'https://apps.apple.com/kr/app/id1641638840';
