import { Outlet } from 'react-router-dom';
import { Header, Footer } from 'layouts/components';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const MainLayout = () => {
	return (
		<div>
			<Header />
			<Box>
				<Outlet />
			</Box>
			<Footer />
		</div>
	);
};

export default MainLayout;
