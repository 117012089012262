import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import {
	Box,
	ButtonBase,
	Container,
	Fade,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';
import { IconArrowDown, IconArrowRight } from 'assets/svg';
import {
	URL_FANCAST_APP_STORE,
	URL_FANCAST_GOOGLE_PLAY_STORE,
	URL_FANCAST_LANDING
} from 'constants/common';

// ----------------------------------------------------------------------

const FancastSection = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const dropdownRef = useRef(null);
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (!dropdownRef.current.contains(event.target)) {
			setIsOpenDropdown(false);
		}
	};

	const handleClickDownloadApp = () => {
		setIsOpenDropdown(!isOpenDropdown);
	};

	// ----------------------------------------------------------------------

	return (
		<Container id={'service-fancast'} maxWidth={'lg'} className={'px-0'}>
			<Grid
				container
				pt={matchUpMd ? '12rem' : '6rem'}
				px={matchUpMd ? 0 : '2rem'}
			>
				{!matchUpMd && (
					<Grid item xs={12} pr={'19.5rem'} mb={'2rem'}>
						<Box className={'Service-Fancast-Logo-Wrapper'}>
							<img src={images.FancastLogoHorizontal} alt={'fancast logo'} />
						</Box>
					</Grid>
				)}
				<Grid item xs={12} mb={matchUpMd ? '6rem' : '2rem'}>
					<Box
						className={'Service-Img-Wrapper'}
						sx={{
							paddingTop: matchUpMd
								? 'calc(560 / 1200 * 100%)'
								: 'calc(240 / 335 * 100%)'
						}}
					>
						<img src={images.ServiceFancast} alt={'fancast'} />
					</Box>
				</Grid>
				{matchUpMd && (
					<Grid item xs={12} md={4} pr={'18rem'}>
						<Box className={'Service-Fancast-Logo-Wrapper'}>
							<img src={images.FancastLogoHorizontal} alt={'fancast logo'} />
						</Box>
					</Grid>
				)}
				<Grid item xs={12} md={8}>
					<Box>
						<Typography
							variant={matchUpMd ? 'caption2' : 'caption5'}
							letterSpacing={i18n.language === 'jp' && '-0.05rem'}
							color={'black2'}
						>
							<Trans
								i18nKey={
									matchUpMd
										? '서비스 소개.가장 트렌디한 글로벌 팬덤 플랫폼, 팬캐스트 글로벌 팬덤 플랫폼인 FANCAST는 글로벌 K-POP 시상식 공식 투표 서비스를 비롯하여 차트, 펀딩, NFT 등 글로벌 팬덤의 트렌드를 반영함으로써 글로벌 콘텐츠 아카이브 플랫폼으로 성장할 계획입니다.'
										: '서비스 소개.M가장 트렌디한 글로벌 팬덤 플랫폼, 팬캐스트 글로벌 팬덤 플랫폼인 FANCAST는 글로벌 K-POP 시상식 공식 투표 서비스를 비롯하여 차트, 펀딩, NFT 등 글로벌 팬덤의 트렌드를 반영함으로써 글로벌 콘텐츠 아카이브 플랫폼으로 성장할 계획입니다.'
								}
							/>
						</Typography>
					</Box>
					<Box display={'flex'} mt={matchUpMd ? '4rem' : '2rem'}>
						<ButtonBase
							onClick={() => window.open(URL_FANCAST_LANDING, '_blank')}
							className={'Service-Button'}
							sx={{
								mr: matchUpMd ? '1.6rem' : '1.2rem',
								px: matchUpMd ? '3.2rem' : '2.4rem',
								py: matchUpMd ? '1.6rem' : '1.2rem'
							}}
						>
							<Box display={'flex'} alignItems={'center'}>
								<Typography
									variant={
										matchUpMd
											? 'caption2'
											: i18n.language === 'ko'
											? 'caption4'
											: 'caption6'
									}
									color={'black1'}
									fontWeight={matchUpMd ? 600 : 500}
									mr={'0.8rem'}
								>
									{t('서비스 소개.사이트 바로가기')}
								</Typography>
								<IconArrowRight
									width={matchUpMd ? 16 : 12}
									height={matchUpMd ? 16 : 12}
									stroke={theme.palette.black1}
								/>
							</Box>
						</ButtonBase>
						<Box ref={dropdownRef} position={'relative'}>
							<ButtonBase
								onClick={handleClickDownloadApp}
								className={'Service-Button'}
								sx={{
									px: matchUpMd ? '3.2rem' : '2.4rem',
									py: matchUpMd ? '1.6rem' : '1.2rem'
								}}
							>
								<Box display={'flex'} alignItems={'center'}>
									<Typography
										variant={
											matchUpMd
												? 'caption2'
												: i18n.language === 'ko'
												? 'caption4'
												: 'caption6'
										}
										color={'black1'}
										fontWeight={matchUpMd ? 600 : 500}
										mr={'0.8rem'}
									>
										{t('서비스 소개.앱 다운받기')}
									</Typography>
									<IconArrowDown
										width={matchUpMd ? 16 : 12}
										height={matchUpMd ? 16 : 12}
										stroke={theme.palette.black1}
									/>
								</Box>
							</ButtonBase>
							{isOpenDropdown && (
								<Fade in={isOpenDropdown}>
									<Box
										className={'Service-Fancast-Dropdown-Item-Wrapper'}
										top={matchUpMd ? '6.2rem' : '5rem'}
									>
										<ButtonBase
											disableRipple
											className={'Service-Fancast-Dropdown-Item'}
											onClick={() =>
												window.open(URL_FANCAST_GOOGLE_PLAY_STORE, '_blank')
											}
										>
											Android
										</ButtonBase>
										<ButtonBase
											disableRipple
											className={'Service-Fancast-Dropdown-Item'}
											onClick={() =>
												window.open(URL_FANCAST_APP_STORE, '_blank')
											}
										>
											iOS
										</ButtonBase>
									</Box>
								</Fade>
							)}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default FancastSection;
